const messages = {
  it: {
    orders: {
      actions: {
        addNewOrder: 'Aggiungi un ordine',
        all: 'Tutti gli ordini',
      },
      listPage: {
        title: 'Ordini',
        table: {
          filter: {
            number: 'Numero',
          },
          columns: {
            contact: 'Contatto',
            dateOfOrder: "Data dell'ordine",
            number: 'Numero',
            offer: 'Offerta',
            opportunity: 'Opportunità',
            owner: 'Proprietario',
            state: 'Posizione',
            total: 'Totale',
            totalUserCurrency: 'Totale in {currency}',
          },
        },
      },
      order: {
        newEntity: 'nuovo ordine',
        contact: {
          label: 'Contatto',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Commissione totale',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Valuta',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: "Data dell'ordine",
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Numero',
          helperText: 'Lascia vuoto per il completamento automatico del numero.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Offerta',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Opportunità',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Proprietario',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Margine commerciale',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Soggetti',
          discount: {
            label: 'Sconto',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Commissione',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Dimensione del pacchetto',
          },
          listPrice: {
            label: 'Prezzo di listino',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Prezzo',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Prodotto',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Quantità',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Margine commerciale',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Totale prodotti',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Unità',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Totale',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {nessun prodotto} one {1 prodotto} few {{number} prodotti} other {{number} prodotti}}',
      },
      myOrdersScoringBlog: {
        title: 'I miei ordini',
      },
      ordersList: 'Ordini',
    },
  },
};
export default messages;
