const messages = {
  it: {
    app: {
      about: 'A proposito di applicazione',
      actions: {
        add: 'Aggiungi',
        addNamed: 'Aggiungi {name}',
        remove: 'Elimina',
        removeNamed: 'elimina {name}',
        removeConfirmation: 'Eliminare il prodotto dal carrello?',
        more: 'Di più',
        less: 'Meno',
        download: 'Trasferimento dati',
        save: 'Salva',
        cancel: 'Cancellalo',
        close: 'Chiudere',
        moreFormFields: 'Più campi',
        export: 'Esportare',
        edit: 'Modificare',
        createdSuccessMessage: 'Creato con successo.',
        savedSuccessMessage: 'Salvato con successo.',
        deletedSuccessMessage: 'Eliminato con successo.',
        detail: 'Dettaglio',
        next: 'Prossimo',
        back: 'Indietro',
        start: 'Inizio',
        whatNext: 'Quello che viene dopo...',
        confirm: 'Confermare',
        confirmRemove: 'Eliminare il prodotto dal carrello?',
        search: 'Cerca',
        userProfile: 'Profilo utente',
        tenantProfile: 'Il tuo profilo CRM',
        imports: 'Importare',
        fileImports: 'Importa file',
        settings: 'Impostazioni',
        logout: 'Disconnettersi',
        login: 'Accedere',
        filterList: 'Elenco di filtri',
        excel: 'Excel',
        refresh: 'Ricaricare',
        clear: 'Vuoto',
        agree: "D'accordo",
        disagree: 'Disaccordo',
        makeCopy: 'Fare una copia',
        fullscreen: 'Visualizza a schermo intero',
        fullscreenClose: 'Chiudi la visualizzazione a schermo intero',
        copy: 'copia',
      },
      messages: {
        confirmRemove: 'Sei sicuro di voler eliminare questo prodotto?',
        errorOccurred: 'Errore',
        createdSuccess: 'La creazione ha avuto successo.',
        savedSuccess: 'Il salvataggio ha avuto successo.',
        deletedSuccess: 'Rimuovi riuscita.',
        uploadSuccess: 'caricamento riuscito',
        uploadError: 'Errore durante il download del file.',
        uploadInProgress: 'Caricamento in corso...',
        uploadedFiles:
          '{number, plural, one {1 file caricato} few {{number} file caricati} other {{number} file caricati}}',
      },
      error: 'Errore',
      validations: {
        isRequired: 'Il campo {field} è obbligatorio.',
        minLengthIsRequired:
          'Sono richiesti almeno {number, plural, one {1 articolo} few {{number} articoli} other {{number} prodotti}} sono richiesti.',
        minValue: 'valore minimo è {value}.',
        maxValue: 'valore massimo è {value}.',
        passwordMatchError: 'la password non corrisponde',
        invalidEmail: 'Email non valida.',
        invalidPhoneNumber:
          "Il numero di telefono deve essere in un formato internazionale valido! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Numero non valido',
        invalidUrl: 'URL non valido.',
        positive: 'Il numero deve avere un valore positivo.',
        negative: 'Il numero deve essere negativo.',
      },
      login: {
        title: 'Accedi a Sprinx CRM',
        username: 'Nome utente',
        password: 'Password',
        loginButton: 'Accedi',
        error: 'Nome utente o password non trovati',
        pleaseRelogin: "Sembra che tu non abbia effettuato l'accesso. Riprova ad accedere.",
      },
      days: '{count, plural, one {1 giorno} few {{formattedCount} giorni} other {{formattedCount} giorni}}',
      overdue: 'ritardo {count, plurale, uno {1 giorno} few {{formattedCount} giorni} other {{formattedCount} giorni}}',
      newItem: 'Nuovo',
      anonymizing: {
        confirm: {
          title: "Conferma l'anonimizzazione",
          content: 'Sei sicuro di voler rendere anonimi i dati personali?',
        },
        button: 'Anonimizzazione dei dati personali',
      },
      appMenu: {
        discover: 'Scoprire',
      },
      missingType: "Innanzitutto, inserisci {typeName} nell'amministrazione dell'applicazione.",
      missingTypeForEntityType:
        "Innanzitutto, definisci {typeName} per {forEntityTypeName} nell'amministrazione dell'applicazione.",
      emptyScoringBlog:
        'I moduli il cui contenuto o le cui impostazioni sono visualizzati in questa pagina non sono attivati.',
    },
    components: {
      buttons: {
        showOnMap: 'mostra sulla mappa',
      },
      filters: {
        filterButtonSubmit: 'Cerca',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Aggiungi una condizione',
        resetFilter: 'Reimposta il filtro',
        operators: {
          contains: {
            0: 'contiene',
            _: 'contiene',
          },
          containsexact: {
            0: 'contiene esattamente',
            _: 'contiene esattamente',
          },
          notcontains: {
            0: 'non contengono',
            _: 'non contengono',
          },
          betweennumber: {
            0: 'dal numero',
            1: 'al numero',
            _: 'tra numero',
          },
          betweendate: {
            0: 'dalla data',
            1: 'alla data',
            _: 'tra data',
          },
          equal: {
            0: 'uguale',
            _: 'uguale',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Numero totale di prodotti: {number}',
        nextPage: 'Elenco dei prodotti: {number}',
        emptyMessage: 'Nessun prodotto trovato.',
      },
      selects: {
        emptyOption: 'Nessuno',
        emptyMessage: 'Nessun prodotto trovato.',
      },
      tables: {
        labelRowsPerPage: 'Riga per pagina',
        sortBy: 'Ordina per {field}',
        recordsChanged: 'Alcune voci sono state aggiunte o modificate.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Formato data irregolare.',
        invalidDate: 'Non valido',
        maxDateMessage: 'La data non può essere maggiore della data massima',
        minDateMessage: 'La data non deve essere inferiore alla data minima',
        today: 'In data odierna',
      },
      detailNavigation: {
        scoringblog: 'Blog sul punteggio',
        card: 'Totale',
        relations: 'Essere',
      },
      manipulationInfo: {
        created: 'Creato',
        updated: 'Modificata',
        removed: 'Eliminato',
      },
    },
  },
};
export default messages;
