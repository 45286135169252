import PropTypes from 'prop-types';
import messages from './it';
import comOrdersMessages from './react-com-orders/it';
import comMessages from './react-com/it';
import formsMessages from './react-forms/it';
import compMessages from './react-mui-components/it';
import tablesMessages from './react-mui-tables/it';
import shoppingCartMessages from './react-shopping-cart/it';

// const rename = ({ sr }) => ({ hr: sr });

function MessagesIt({ children }) {
  return children(
    [
      compMessages,
      // fieldMessages,
      tablesMessages,
      formsMessages,
      // pagesMessages,
      comMessages,
      // shoppingListMessages,
      shoppingCartMessages,
      comOrdersMessages,
      // invoicesMessages,
      messages,
    ], // .map(rename),
  );
}

MessagesIt.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MessagesIt;
