const messages = {
  it: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Carrello',
        },
      },
      productDetail: {
        parameters: {
          title: 'Scheda Tecnica',
        },
      },
      productAvailability: {
        format: {
          available: 'Disponibile',
          moreThanX: 'Più di {min} pezzi in stock',
          exactCount: '{value} pezzi in stock',
          notAvailable: 'Non disponibile',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ più ({number}).',
      },
      relatedProducts: {
        title: 'Potrebbe interessarti anche',
      },
      productAccessories: {
        title: 'Accessori',
      },
      productDetailImageGallery: {
        noImage: 'Nessuna immagine',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Quando riceverò la merce?',
      },
    },
  },
};
export default messages;
