/* eslint-disable no-unused-vars */
import { asyncComponent, AsyncRouteProps } from '@jaredpalmer/after';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { match as Match } from 'react-router-dom';
import Placeholder from './AppComponentLoader';
import { SupportedLocale } from './supported';

const routesDefinition = {
  home: {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/HomePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  // test: {
  //   path: '/test',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('./pages/Test'),
  //     Placeholder,
  //   }),
  //   urlBuilder: routesBuilderDefaultdUrlBuilder,
  // },
  article: {
    path: '/info/:path*',
    exact: false,
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  newsList: {
    path: '/news',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/NewsListPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  newsDetail: {
    path: '/news/:path*',
    exact: false,
    component: asyncComponent({
      loader: () => import('./pages/NewsDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  aboutShopping: {
    path: '/tutto-sullo-shopping',
    exact: false,
    component: asyncComponent({
      loader: () => import('./pages/AboutShoppingPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cart: {
    path: '/cart/content',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartSinglePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  thankYou: {
    path: '/order-saved-confirmation',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderSavedThankYouPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  registration: {
    path: '/registration',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/RegistrationPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profile: {
    path: '/profile',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfilePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  productDetail: {
    path: '/:pathParts*-product-:kod([^/]+)',
    component: asyncComponent({
      loader: () => import('./pages/ProductDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  search: {
    path: `/search`,
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/SearchResultsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  compare: {
    path: `/compare`,
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ProductComparePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  catalogue: {
    path: `/category/:pathParts*`,
    exact: false,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
};

/**
 * Returnd builded URL from `type` and params...
 *
 * ```
 * routeUrl('home', {locale, params: { ... } }); // => '/'
 * routeUrl('productDetail', {locale, params: { ... } }); // => '/some-product-name-product-12344'
 * ```
 */
export function routeUrl<T extends keyof typeof routesDefinition, P extends typeof routesDefinition[T]['urlBuilder']>(
  type: T,
  opts: {
    locale: SupportedLocale;
    otherParamsToQs?: boolean | string[];
    params: P extends (...args: any[]) => any ? Parameters<P>[3] : Record<string, any> | undefined;
  },
): string {
  const path = routePath(type, opts);
  const def = routesDefinition[type];
  const inputParams = opts.params || {}; // ensure params exists

  return def.urlBuilder && typeof def.urlBuilder === 'function'
    ? def.urlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any)
    : routesBuilderDefaultdUrlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any);
}

export function routeIsActive(
  type: keyof typeof routesDefinition,
  opts: {
    customMatcher?: (opts: {
      nodePath: string;
      pathRegexMatches: RegExpExecArray | null;
      routeRegexMatch: boolean;
    }) => boolean;
    locale: SupportedLocale;
    match: Match<any>;
  },
): boolean {
  const nodePath = routePath(type, opts);

  const re = pathToRegexp(opts.match.path, undefined, { strict: opts.match.isExact });
  const pathRegexMatches = re.exec(nodePath);
  const routeRegexMatch = pathRegexMatches !== null;

  return opts.customMatcher ? opts.customMatcher({ nodePath, routeRegexMatch, pathRegexMatches }) : routeRegexMatch;
}

/**
 * Returns a `path` from roteDefinitions by `type` and `opts.locale`.
 *
 * ```
 * routePath('home', { locale }); // => '/'
 * routePath('productDetail', { locale }); // => '/:sn([^/]+)-product-:id([^/]+)'
 * ```
 */
export function routePath(type: keyof typeof routesDefinition, opts: { locale: string }): string {
  const def = routesDefinition[type];
  return typeof def.path === 'string' ? def.path : def.path[opts.locale];
}

/**
 * Standard rou
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderDefaultdUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: Record<string, any> | undefined,
): string {
  const { pathParams, queryParams, otherParamsExists } = routesBuilderSplitInputParams(
    otherParamsToQs,
    path,
    inputParams || {},
  );

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}

/**
 * Returns `inputParameters` object splitted to two separate
 * @param otherParamsToQs
 * @param path
 * @param inputParams
 */
export function routesBuilderSplitInputParams<P extends Record<string, any>>(
  otherParamsToQs: boolean | string[] | undefined | null,
  path: string,
  inputParams: P,
): { otherParamsExists: boolean; pathParams: Record<string, any>; queryParams?: Record<string, any> } {
  const [pathParams, queryParams] = !otherParamsToQs
    ? [inputParams]
    : ((reg) => {
        const pathParamKeys = reg.keys.map((i) => i.name);
        return Object.entries(inputParams).reduce<[Record<string, any>, Record<string, any>]>(
          (acc, [k, v]) => {
            if (pathParamKeys.includes(k)) {
              return [{ ...acc[0], [k]: v }, acc[1]];
            }

            if (Array.isArray(otherParamsToQs) && !otherParamsToQs.includes(k)) {
              return acc;
            }

            return [acc[0], { ...acc[1], [k]: v }];
          },
          [{}, {}],
        );
      })(pathToRegexp(path));

  const otherParamsExists = !!queryParams && Object.keys(queryParams).length > 0;

  return {
    pathParams,
    queryParams,
    otherParamsExists,
  };
}

/**
 * Returns all router routes definitions...
 */
export function routeAllRoutesToRouter(): AsyncRouteProps[] {
  const extr = ({ urlBuilder, path, ...rest }: RouteDefinition): Omit<AsyncRouteProps, 'path'> => rest;

  // @ts-ignore
  return Object.values(routesDefinition).reduce<AsyncRouteProps[]>((acc, itm) => {
    if (typeof itm.path === 'string') return [...acc, { path: itm.path, ...extr(itm as any) }];
    return [...acc, ...Object.values(itm.path).map((path) => ({ path, ...extr(itm as any) }))];
  }, []);
}

const safeAbsoluteUrl = (path: string, url: string): string =>
  `${path.startsWith('/') ? '/' : ''}${url.replace(/^\//, '')}`;

export interface RouteDefinition extends Omit<AsyncRouteProps, 'path'> {
  path: string | Record<SupportedLocale, string>;
  urlBuilder: (
    locale: SupportedLocale,
    path: string,
    otherParamsToQs: boolean | string[] | undefined | null,
    params: any,
  ) => string;
}
