const messages = {
  it: {
    reactMuiTables: {
      selected: 'selezionato',
      filterhide: 'Nascondi filtro',
      filtershow: 'Mostra filtro',
      refresh: 'Ripristina',
      filter: {
        actions: {
          search: 'Cerca',
          resetFilter: 'Reimposta il filtro',
          addCondition: 'Aggiungi una condizione',
        },
      },
      standardOperators: {
        equals: 'equivale',
        contains: 'contiene',
        between: {
          label: 'fra',
          from: 'da',
          to: 'a',
        },
        oneOf: 'uno di',
      },
      labelRowsPerPage: 'Righe per pagina:',
    },
  },
};
export default messages;
