const messages = {
  it: {
    parameters: {
      yes: 'UN',
    },
    com: {
      phase: {
        label: 'Designazione',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Codice prodotto',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Produttore',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Numero',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Garanzia',
          months: 'mesi',
        },
      },
      totalAmount: {
        label: 'Totale',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ ({number}) immagini',
    },
    orders: {
      actions: {
        all: 'Tutti gli ordini',
      },
      myOrdersScoringBlog: {
        title: 'I miei ordini',
      },
      ordersList: 'Ordini',
      phase: {
        1: 'Ricevuto',
        '01-new': 'Ricevuto',
        10: 'Viene considerato',
        20: 'In attesa del pagamento',
        30: 'Pagato',
        98: 'Stabilito',
        99: 'Annullato',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Numero del pacchetto',
            phase: 'Stato',
            paid: 'Pagato',
            total: 'Senza tasse',
            totalWithTax: 'Totale',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Carrello',
        buyButton: 'Effettua Ordine',
        emptyBasket: 'Il tuo carrello è vuoto',
        buyThere: 'Sei già un cliente?',
        login: 'Fai clic qui per accedere',
        notFill: 'Le informazioni sulla consegna non sono compilate',
        fill: 'Compila le informazioni',
        change: 'Modifica dati',
        popupButton: 'mostra sulla mappa',
        payment: {
          title: 'METODO DI PAGAMENTO',
          free: 'Gratuito',
          changePaymentBtn: 'MODIFICA PAGAMENTI',
        },
        shipping: {
          title: 'METODO DI CONSEGNA',
          free: 'Gratuito',
          changeShippingBtn: 'MODIFICA DI CONSEGNA',
          popup: {
            title: 'Informazione',
            text: 'Consegna al tuo indirizzo direttamente con corriere {service}',
          },
        },
      },
      actions: {
        all: 'Tutti i carrelli',
      },
      myBasketsScoringBlog: {
        title: 'Carrelli salvati',
      },
      cartSummaryParameters: {
        caption: 'Codice prodotto',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Descrizione',
      parameters: 'Parametri',
      package: 'Accessori',
      fotoAndVideo: 'Immagine e video',
      alternatives: 'Alternativa',
      alternativesText: 'Nessuna alternativa per il prodotto selezionato.',
      questions: 'Domande',
      productDescriptionNotAvailable: 'Descrizione del prodotto non disponibile',
    },
    newsletter: {
      phone: '+386 1 82 82 054',
      subscribe: {
        title: "Non perdere nemmeno un'offerta",
        inputText: 'Indirizzo email',
        button: 'Iscrivimi',
      },
    },
    globalNavigation: {
      home: 'Home',
      shopping: 'Continua gli acquisti',
      orders: 'Ordini',
      shoppingLists: 'Liste della spesa',
      articles: 'Magazine',
    },
    homePageWhy: {
      0: {
        title: 'Pagamento sicuro',
        content:
          'Sistema di pagamento con autenticazione 3D Secure che garantisce una sicurezza ottimale durante gli acquisti online.',
      },
      1: {
        title: 'Soddisfatti o rimborsati',
        content:
          'Hai il diritto di cambiare idea. Approfitta del nostro rapido servizio di restituzione e rimborso entro 14 giorni',
      },
      2: {
        title: 'Magazzino in tempo reale',
        content:
          "Merce in proprio magazzino. Abbiamo il 98% dell'intera gamma in magazzino per la spedizione immediata.",
      },
      3: {
        title: 'Consegna gratuita',
        content:
          "Con l'acquisto di fotocamere digitali, obiettivi e altri prodotti selezionati beneficerai della consegna GRATUITA.",
      },
      title: 'PERCHÉ NOI?',
    },
    startPage: {
      title: 'Home',
      gotoShopping: 'Continua gli acquisti',
    },
    shoppingCart: {
      actions: {
        buy: 'AGGIUNGI',
      },
      buyBtn: 'AGGIUNGI AL CARRELLO',
      alternatives: 'ALTERNATIVA',
      shipping: {
        changePoint: 'Modifica',
      },
      agreements: {
        termsRequired: 'Si prega di accettare le condizioni commerciali.',
      },
      validation: {
        someProductNotAvailable: 'Alcuni prodotti non sono disponibili in quantità sufficienti.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Codice prodotto:',
    },
    productListPage: {
      title: 'Catalogare',
      orders: {
        recommended: 'Rilevanza',
        name: 'Alfabetico',
        priceAsc: 'Prezzo crescente',
        priceDesc: 'Prezzo decrescente',
      },
    },
    orderStatuses: {
      storno: 'Cancellazione',
      synchronized: 'Spedito',
      waiting: 'In attesa di sincronizzazione',
    },
    orderPreview: {
      items: 'prodotti',
    },
    cartRegisterForm: {
      title: 'Modulo di registrazione',
    },
    cartPayment: {
      title: 'METODO DI PAGAMENTO',
    },
    cartPreSummaryPage: {
      title: 'riepilogo carrello',
      actions: {
        nextStep: 'Accetto e proseguo',
      },
    },
    cartCompanyForm: {
      description: 'Desidera ricevere fattura con codice fiscale?',
    },
    cartDeliveryForm: {
      description: "Compila se l'indirizzo di spedizione è diverso dall'indirizzo di fatturazione",
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Quando riceverò la merce ordinata?',
    },
    productDetailStockInfo: {
      available: 'Indica la disponibilità immediata in magazzino e relativa data prevista di consegna.',
      external: 'Prodotto in arrivo',
      notAvailable: 'Non disponibile',
      labels: {
        free: 'CONSEGNA GRATUITA',
        showroom: 'VISUALIZZATO NELLO SHOWROOM',
      },
    },
    productGifts: {
      btnName: 'REGALO',
      giftValue: 'Del valore di',
    },
    homepage: {
      productOfMonth: 'OFFERTE DEL GIORNO',
      catalogFavorite: 'Categorie più ricercate',
      hotTips: 'Suggerimenti popolari',
      recommended: 'Potrebbe interessarti anche',
    },
    productQuestion: {
      firstName: 'Nome',
      lastName: 'Cognome',
      email: 'Email',
      subject: 'Domanda',
      question: 'Domanda',
      gdpr: 'Acconsento al trattamento dei dati personali al fine di ottenere una risposta alla domanda.',
      gdprLink: 'Collegamento GDPR',
      button: 'INVIA',
      subjectContent: 'Domanda sul prodotto: {product}, codice: {sku}',
      title: 'Devi acconsentire al trattamento dei dati personali.',
      success: 'il modulo è stato inviato',
    },
    lastVisitedProducts: 'Prodotti visionati recentemente',
    productAlternativeProducts: 'Alternativa',
    productAccessoryProducts: 'Accessori',
    productReplacementProducts: 'Aggiunta',
    productAccessories: 'Aggiunta',
    productReplacements: 'Sostituzione',
    appProductDetailTabs: {
      description: 'Descrizione',
      parameters: 'Parametri',
      package: 'Accessori',
      query: 'Domanda',
      media: 'Immagine e video',
      alternatives: 'Alternativa',
      accessories: 'Accessori',
      replacements: 'Accessori',
      form: {
        enum: '!!!! VALORE ENUM',
      },
    },
    cartSummaryPage: {
      title: 'Riepilogo ordine',
      actions: {
        continueShopping: 'continua gli acquisti',
        saveOrder: 'Crea un ordine',
      },
      expectedDeliveryDate: {
        label: 'Data di consegna',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Nota per il corriere',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Casello stradale',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Il mio account',
      content: 'Contenuti',
      contactInfo: {
        newsletter: {
          title: 'Newsletter',
          content: 'Voglio ricevere le notizie di Digiexpert.it',
        },
        title: 'Il mio indirizzo',
      },
      orders: {
        title: 'Cronologia ordini',
      },
      invoices: {
        title: 'Le mie fatture',
      },
      myOrders: 'I miei ordini',
      myInvoices: 'le mie fatture',
      logout: 'Log out',
      login: 'Accedi',
      favorites: {
        title: 'Prodotti preferiti',
      },
      changePassword: {
        title: 'Modifica della password',
        fields: {
          login: 'Email',
          originalPassword: 'Password',
          newPassword: 'Scegli una password',
          confirmNewPassword: 'Conferma password',
        },
        button: 'Salva',
      },
    },
    orderSavedThankPage: {
      title: 'Grazie per il tuo ordine {orderNumber}',
      thankMessage: {
        email: 'info@digiexpert.it',
        phoneNumber: '',
        template:
          "Controlla la tua e-mail per i dettagli completi dell'ordine. Salva l'e-mail per uso personale. Tutte le informazioni relative al tuo ordine sono disponibili nella sezione sottostante.",
      },
      order: "Numero d'ordine:",
      payment: 'Pagamento del giorno:',
      cancelled: 'Annulato',
      accepted: 'Accettato',
      pending: 'In attesa',
      paid: 'Pagato',
      actions: {
        back: 'Torna alla homepage',
        detail: 'RIEPILOGO ACQUISTI',
        continue: 'continua gli acquisti',
      },
      decline: 'Rifiuta',
    },
    shoppingLists: {
      title: 'Liste della spesa',
      actions: {
        saveAsShoppingList: 'Salva come lista della spesa',
        importShoppingListToCart: 'Importare',
      },
    },
    searchResultPage: {
      search: 'Cerca',
      results: 'risultati trovati',
    },
    homePageArticles: {
      title: 'Magazine',
      button: 'Leggi tutti gli articoli',
    },
    loginPage: {
      title: 'Accedi',
      forgotPassword: 'Resetta la password',
      email: 'Per e-mail',
      sendEmail: "Invia un'e-mail",
      info: 'Riceverai una mail con un link per cambiare la tua password',
      password: 'Password',
      notSame: 'La password non corrisponde',
      newPassword: 'Scegli una password',
      confirmPassword: 'Conferma password',
      changePassword: 'Modifica della password',
    },
    productPrice: {
      withoutTax: 'senza tasse',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Acquista {today} e spediremo entro {minDays}-{maxDays}.',
        today: 'Acquista oggi entro {time} e spediremo oggi.',
        todayWord: 'oggi',
        tomorrow: 'Acquista {today} e spediremo domani.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Acquista {today} e vi spediremo {day}.',
          1: 'Lunedi',
          2: 'Martedi',
          3: 'Mercoledì',
          4: 'Giovedì',
          5: 'Venerdì',
          6: 'Sabato',
          0: 'Domenica',
        },
      },
      allDescription: 'DESCRIZIONE DEL PRODOTTO',
      demoProduct: {
        text: 'ACQUISTA PIÙ ECONOMICO per:',
      },
      productNote: {
        title: 'ACQUISTA PIÙ ECONOMICO a: € 2.150,01',
        note: "Nota 2 – Descrizione delle condizioni aggiuntive o informazioni simili sul prodotto perché ha uno sconto e cosa c'è sull'articolo...",
      },
      adornments: {
        notForSale: 'Non in vendita',
        usedProduct: 'Prodotto usato',
        freeGift: 'Regalo gratuito',
        freeShipping: 'Consegna gratuita',
        eol: 'Vendita chiusa',
        demo: 'Prodotto dimostrativo',
        sale: 'Sconto',
      },
      disponibility: {
        primaryLessThan: 'DISPONIBILI meno di {count} pezzi', //Meno di {count} unità DISPONIBILI
        primaryMoreThan: 'DISPONIBILI più di {count} pezzi', //IN MAGAZZINO {count, plural, zero {un pezzo} other {{count} e più pezzi}}
        secondaryLessThan: 'IN ARRIVO meno di {count} pezzi', //IN MAGAZZINO in preparazione magazzino meno di {count} pezzi
        secondaryMoreThan: 'IN ARRIVO più di {count} pezzi ', //IN STOCK nel prossimo magazzino {count, plural, zero {un pezzo unico} altro {{count} e diversi pezzi}}
        external: 'IN ARRIVO',
        unavailable: 'Non disponibile',
        showroom: '(mostrato in Showroom)',
        soldout: 'Esaurito',
        'on-way': {
          d: '',
          m: 'In arrivo in {from, plural, one {minuto} two {minuto} other {minuto}}',
          M: 'In arrivo in {from, plural, one {mese} two {mesi} few {mesi} other {mesi}}',
          dRange: 'In arrivo in {to, plural, few {{from}-{to} giorni} other {{from}-{to} giorni}}',
          mRange: 'In arrivo in {from}-{to, plural, one {giorno} pochi {giorni} other {giorni}}',
          MRange: 'In arrivo in {from}-{to, plural, one {mese} two {mesi} few {mesi} other {mesi}}',
          date: '{datum}',
        },
        time: 'In arrivo {count, plural, zero {ultimo pezzo} altro {{count} e più pezzi}}',
      },
    },
    comparePage: {
      title: 'Confronta prodotti',
      removeButton: 'Rimuovi',
      nothing: 'Niente da confrontare',
    },
    filter: {
      reset: 'Ripristinare i filtri',
      search: 'Cerca',
    },
    orderTermsAndConditions: {
      select: {
        label: "Condizioni d'ordine",
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Aggiungi al carrello',
      },
    },
    invoiceBlogPreview: {
      title: 'Le mie fatture',
      button: 'Tutti i conti',
    },
    userProfilePage: {
      logout: 'Logout',
      setting: 'Impostazioni',
      passwordChange: 'Modifica della password',
    },
    appBarActionRegion: {
      changeRegion: 'Cambia la lingua',
      label: 'Chiudere',
    },
    subCategories: {
      title: 'Categorie più ricercate',
    },
    aboutShoppingPage: {
      title: "Guide all'acquisto",
      contact: 'CONTATTI',
      electronicClosureOfRevenue: 'DOCUMENTAZIONE ELETTRONICA DEI REDDITI',
      informationDuty: 'OBBLIGO DI INFORMAZIONE',
      processingOfPersonalData: 'TRATTAMENTO DEI DATI PERSONALI',
      cashback: 'RIMBORSO',
      delivery: 'TIPI E TEMPI DI CONSEGNA',
      availability: 'DISPONIBILITÀ',
      methodOfPayment: 'METODO DI PAGAMENTO',
      termsAndConditions: 'CONDIZIONI DI VENDITA',
      termsOfAppeal: 'CONDIZIONI DI RESO',
      contractwWithdrawal: 'DIRITTO DI RECESSO',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Il tuo codice fiscale è stato verificato i prezzi sono addebitati senza IVA.',
        viesNotVerified:
          "Il tuo codice fiscale non è stato verificato o non soddisfi le condizioni per l'acquisto senza IVA.",
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'IL TUO DATI',
      pleaseComplete: 'COMPLETA IL TUO DATI',
      closeButton: 'Chiudere',
    },
    cartPromoCodes: {
      title: 'APPLICA IL CODICE PROMO',
      textFieldPlaceholder: 'CODICE PROMO',
      textFieldHelperText: 'È possibile applicare solo un codice promo per ordine',
      applyPromoCodeButton: 'APPLICA',
    },
    cookiesBar: {
      agreeBtn: 'Accetta',
      title: 'Questo sito web utilizza i cookie',
      text: 'Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire funzionalità dei social media e per analizzare il nostro traffico. Condividiamo inoltre informazioni sul modo in cui utilizza il nostro sito con i nostri partner che si occupano di analisi dei dati web, pubblicità e social media, i quali potrebbero combinarle con altre informazioni che ha fornito loro o che hanno raccolto dal suo utilizzo dei loro servizi.',
    },
    cartFormLogin: {
      btnSend: 'INVIA',
      button: 'Accedi',
      title: 'Accedi',
      forgottenPasswordBtn: 'Hai dimenticato la password?',
      text1: "Ti invieremo una password monouso all'indirizzo email che hai fornito.",
      text2: "Dopo aver effettuato l'accesso, impostare una nuova password.",
      error: 'Email o password non valide.',
    },
    cartSummartSection: {
      title: 'RIEPILOGO ACQUISTI',
    },
    appCartSummarySubjectsList: {
      title: 'PRODOTTO',
      text: 'DESCRIZIONE',
    },
    newsletterText: {
      text1: 'Hai bisogno di assistenza?',
      text2: 'Contattaci nei giorni lavorativi dalle 8:00 alle 16:00.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(tasse escluse)',
      priceLabel: 'PREZZO',
      amount: 'QUANTITÀ',
      totalPriceLabel: 'PREZZO TOTALE',
      totalPrice: 'PREZZO TOTALE',
      submitButton: 'INVIA UN ORDINE',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '- Gratuito',
      amountTitle: 'pezzi',
    },
    sxCartNotes: {
      note: 'Non serve per indicare la data di consegna',
      addNote: 'Aggiungi una nota',
    },
    productDetail: {
      promoCode: {
        title: 'con il codice promo:',
        validTo: 'valido fino a:',
      },
      productSendLink: {
        copyLinkBtn: 'Condividi',
        sendLinkByEmail: 'Invia il link per e-mail',
      },
      productDetailBarInformation: {
        addToFavourites: 'Preferiti',
        compareProduct: 'Confronta',
        sendLink: 'Condividi',
        serviceList: 'Assistenza',
        questions: 'Domande',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'FATTURA AD UNA AZIENDA',
        otherDeliveryInfo: 'CONSEGNA A UN INDIRIZZO DIVERSO',
        otherDeliveryInfoAlert: 'Il contribuente IVA non può utilizzare un altro indirizzo per la consegna.',
      },
      companyNameLabel: 'Azienda',
      firstNameLabel: 'Nome',
      lastNameLabel: 'Cognome',
      streetLabel: 'Indirizzo',
      cityLabel: 'Città',
      zipLabel: 'CAP',
      companyIDLabel: 'Codice fiscale',
      countryLabel: 'Stato',
    },
    cartFormPersonal: {
      label: 'Indirizzi di consegna',
      firstNameLabel: 'Nome',
      lastNameLabel: 'Cognome',
      streetLabel: 'Numero civico',
      pscLabel: 'CAP',
      cityLabel: 'Città',
      countryLabel: 'Stato',
      phoneLabel: 'Telefono',
      emailLabel: 'Indirizzo email',
      passwordLabel: 'Password',
      controlPasswordLabel: 'Ripeti la password',
    },
    cartFormInvoice: {
      labelDesktop: 'INFORMAZIONI CLIENTI',
      labelMobile: 'Il mio indirizzo',
      companyLabel: 'Azienda',
      password: 'Password',
      confirmPassword: 'Verifica password',
      email: 'Email',
      firstName: 'Nome',
      lastName: 'Cognome',
      countryLabel: 'Stato',
      phone: 'Telefono',
      phoneError: 'Il numero di telefono non è corretto',
      streetLabel: 'Indirizzo',
      streetNumberLabel: 'Numero civico',
      zipLabel: 'CAP',
      icoLabel: 'n.',
      dicLabel: 'codice fiscale',
      country: 'Stato',
      cityLabel: 'Città',
      registration: 'Crea un account',
      registrationNote: 'Non hai ancora un account?',
      button: 'Salvare le modifiche',
    },
    page404: {
      title: 'Purtroppo la pagina non esiste...',
      subTitle: 'Home',
      button: 'Home',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Ordina per',
      byName: 'Nome',
      byPrice: 'Prezzo',
      onlyStock: 'Pronta consegna',
      resetFilter: 'Annulla',
      displayCountOfProducts: 'Prodotti:',
      search: 'Cerca',
      clear: 'Rimuovere',
      ok: 'OK',
    },
    priceRangeSliderField: {
      title: 'Fascia di prezzo',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtra per',
    },
    productListMultipleFilterMobile: {
      title: 'FILTRO',
    },
    cartCompany: {
      companyName: 'Azienda',
      regNo: 'Codice Fiscale',
      taxNo: 'Partita IVA',
      taxNoPlaceholder: 'IT00000000000',
      warningMessage: {
        warning: 'Importante.',
        message: "In caso di consegna ad un indirizzo diverso da quello della società, l'ordine sarà soggetto a IVA.",
      },
    },
    searchResultsPage: {
      match: '{resultCountFormatted} risultati trovati per {searchText}.',
      nomatch: 'Nessun prodotto per {searchText}.',
      matchingCategories: 'Categorie trovate',
      button: 'Home',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Prodotti selezionati: {total}',
    },
    cartDelivery: {
      firstName: 'Nome',
      lastName: 'Cognome',
      companyName: 'Azienda',
      streetLabel: 'Indirizzo',
      streetNumberLabel: 'Numero civico',
      zipLabel: 'CAP',
      country: 'Stato',
      cityLabel: 'Città',
    },
    cartNotes: {
      shippingLabel: 'Aggiungi una nota al corriere',
      shippingNote: 'Nota per i vettori',
      sellerLabel: 'Aggiungi una nota al venditore',
      sellerNote: 'Nota per i venditori',
      loginNote: "Effettua l'accesso con i tuoi dati personali e prosegui con gli acquisti e le operazioni desiderate.",
    },
    cartCountryShipping: {
      title: 'ZONA DI CONSEGNA',
      label: 'La scelta del Paese influisce sul metodo di pagamento e spedizione, nonché sulla data di consegna',
    },
    cartAddress: {
      bllingAddress: 'Indirizzo di fatturazione',
      deliveryAddress: 'Indirizzo di consegna',
      pickUpPointAddress: 'Indirizzo del punto di ritiro',
    },
    cartAgreements: {
      customerVerified: 'Non accetto di inviare un questionario al servizio Trusted Program.',
    },
    loginDialog: {
      username: 'Email',
      password: 'Password',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Le password inserite non sono le stesse',
      invalidPostalCode: 'Il CAP inserito non è in un formato valido',
      usernameIsNotFree: 'Un utente con questo nome è già registrato.',
      emailEmpty: "L'e-mail è corretta!",
    },
    invoices: {
      actions: {
        addNewInvoice: 'Aggiungi un nuovo account',
        all: 'Tutti i conti',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Numero',
            invoiceDate: 'data fattura',
            dueDate: 'Scadenza',
            total: 'Senza tasse',
            totalWithTax: 'Totale',
            billingAddress: 'Indirizzo di fatturazione',
            orderNumber: "Numero d'ordine",
            unpaidAmount: 'Importo non pagato',
          },
          filter: {
            number: 'Numero',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Righe di conto',
          columns: {
            priceUnit: 'Prezzo',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Totale IVA inclusa',
      tax: 'I.V.A',
      withoutTax: 'Prezzo totale IVA esclusa',
    },
    compare: {
      addToCompare: 'prodotti aggiunti al confronto',
      removeToCompare: 'prodotti rimossi dal confronto',
      price: 'Prezzo',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'prodotti aggiunti ai preferiti',
      removeFromFavorite: 'prodotti rimossi dai preferiti',
    },
    registration: {
      important: 'IMPORTANTE!',
      note: "Ti invieremo una password monouso via e-mail. Dopo aver effettuato l'accesso, inserisci una nuova password.",
      gdprAgreement: 'Devi acconsentire al trattamento dei dati personali',
      submitButton: 'Registrati',
      fail: 'Registrazione fallita, riprova più tardi',
      success: 'registrazione avvenuta con successo',
      title: 'Registrazione',
      dialog: 'Già cliente? Se non hai un account puoi registrarti',
      here: 'qui',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Cerca prodotti',
    },
    appBarActions: {
      about: 'Tutto sullo shopping',
      aboutHref: '/tutto-sullo-shopping',
      compare: 'Confrontare',
    },
    appBarActionUser: {
      title: 'Accedi',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O., P.IVA: 00351319991, TUTTI I DIRITTI RISERVATI',
    },
    appArticlesPage: {
      title: 'Testi',
    },
    errorPage: {
      title: 'Qualcosa è sbagliato.',
      button: 'Home',
    },
    cartSummary: {
      withoutTax: 'senza tasse',
    },
    renewPasswordForm: {
      success: 'Una nuova password è stata inviata al tuo indirizzo email.',
      emailNotFound: 'E-mail non trovata!',
      error: 'Qualcosa è sbagliato',
    },
    cartSubjectListItemQuantityWarning: "La quantità massima dell'ordine è {count} prodotti",
  },
};
export default messages;
