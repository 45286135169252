const messages = {
  it: {
    sprinxFormsValidation: {
      required: 'Il campo è obbligatiorio!',
      string: 'Deve essere una stringa di testo!',
      stringEmpty: 'Non deve essere una stringa vuota',
      stringMin: 'La lunghezza deve essere maggiore o uguale a {expected} caratteri!',
      stringMax: 'La lunghezza deve essere inferiore o uguale a {expected} caratteri!',
      stringLength: 'La lunghezza deve essere di {expected} caratteri!',
      stringPattern: 'Non corrisponde al modello richiesto!',
      stringContains: "Il testo '{expected}' deve essere incluso!",
      stringEnum: 'Non corrisponde a nessuno dei valori consentiti!',
      stringNumeric: 'Quello deve essere il numero!',
      number: 'Quello deve essere il numero!',
      numberMin: 'Deve essere maggiore o uguale a {expected}!',
      numberMax: 'Deve essere minore o uguale a {expected}!',
      numberEqual: 'Deve essere uguale a {expected}!',
      numberNotEqual: 'Non deve essere uguale a {expected}!',
      numberInteger: 'Deve essere un numero intero!',
      numberPositive: 'Il numero deve avere un valore positivo!',
      numberNegative: 'Il numero deve essere negativo.',
      array: 'Questa deve essere la lista!',
      arrayEmpty: 'Non deve essere una lista vuota!',
      arrayMin:
        'Ci deve essere almeno {number, plural, one {1 prodotto} few {{number} prodotti} other {{number} prodotti}}!',
      arrayMax:
        'Non può contenere più di {number, plural, one {1 prodotto} few {{number} prodotti} other {{number} prodotti}}!',
      arrayLength:
        'Deve contenere esattamente {expected, plural, one {1 prodotto} few {{number} prodotti} other {{number} prodotti}}!',
      arrayContains: "Deve contenere il prodotto '{expected}'!",
      arrayEnum: "Il valore '{expected}' non corrisponde a nessuno dei valori consentiti!",
      boolean: 'Deve essere "sì" ma "no"!',
      function: 'Deve essere una caratteristica!',
      date: 'Questo deve essere un appuntamento!',
      dateMin: 'Deve essere maggiore o uguale a {expected}!',
      dateMax: 'Deve essere minore o uguale a {expected}!',
      forbidden: 'Interdite!',
      email: "L'e-mail è corretta!",
      url: 'URL non valido!',
      object: 'Deve essere di tipo Oggetto!',
      arrayReducedEquals: 'Il valore aggregato non corrisponde a {expected}!',
      phoneNumber: "Il numero di telefono deve essere in un formato internazionale valido! '+ XXX XXX XXX XXX '!",
      dateGt: 'La data deve essere maggiore di {expected}!',
      dateGte: 'La data deve essere maggiore o uguale a {expected}!',
      dateLt: 'La data deve essere inferiore a {expected}!',
      dateLte: 'La data deve essere minore o uguale a {expected}!',
    },
  },
};
export default messages;
