import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    tagContainer: {
      padding: theme.spacing(0, 1),
      '& img': {
        width: 90,
        height: 90,
        margin: theme.spacing(0, 1.5),
        [theme.breakpoints.down('md')]: { width: 70, height: 70, margin: theme.spacing(0, 1.25) },
      },
    },
  }),
  { name: 'ProductDetailCertificate' },
);

function ProductDetailCertificate({ classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses });

  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      <div
        className={classes.tagContainer}
        style={{ background: 'transparent', textAlign: 'center', padding: 0, margin: '0 auto' }}
      >
        <a
          title='Idealo.it - Bewertung des Shops Digiexpert.at'
          href='https://www.idealo.it/negozio/329546/digiexpert-it.html'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://img.idealo.com/folder/Shop/329/5/329546/s10_idealo-partner.png'
            alt='Idealo.it - Bewertung des Shops Digiexpert.at'
            style={{ borderStyle: 'none' }}
          />
        </a>
      </div>
    </div>
  );
}

ProductDetailCertificate.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    tagContainer: PropTypes.string,
  }),
  className: PropTypes.string,
};

ProductDetailCertificate.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default ProductDetailCertificate;
