const messages = {
  it: {
    shoppingCart: {
      actions: {
        addToCart: 'Aggiungi al carrello',
        buy: 'Acquista',
        order: 'Acquista',
        remove: 'Rimuovi',
      },
      cart: {
        total: {
          label: 'Totale',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Totale articoli',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Spese di spedizione',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Totale',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Totale IVA',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Svuota carrello',
      },
      messages: {
        confirmResetCart: 'Sei sicuro di voler eliminare il contenuto del carrello?',
        giftClaim: 'Puoi ricevere {quantity} pezzi di {productName} in regalo.',
        cartManipulations: {
          allRemoved: 'Tutti gli articoli sono stati rimossi dal carrello.',
          itemAdded: '{number, plural, one {oggetto aggiunto al carrello} other {oggetti aggiunti al carrello}}.',
          itemRemoved: '{number, plural, one {Articolo rimosso dal carrello} other {Articoli rimossi dal carrello}}.',
          quantityChaged: 'La quantità acquistata è cambiata.',
        },
      },
      components: {
        appBartActionCart: 'Carrello',
        quantityField: {
          states: {
            quantityRounded: 'La quantità è stata arrotondata alla dimensione del pacco più vicina.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Il tuo carrello è vuoto',
        },
        cartAfterAddModal: {
          continueShopping: "continua l'acquisto",
          goToCart: 'Vai al carrello',
          title: 'Prodotti aggiunti al carrello',
          pcs: '',
        },
      },
    },
  },
};
export default messages;
